<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form @submit.prevent="submit">
          <!-- form -->
          <div class="row">
            <div class="col-6">
              <base-input
                label="Título da categoria"
                v-model="form.title"
              ></base-input>
            </div>

            <div class="col-6">
              <label for="">Tipo da categoria</label>
              <select v-model="form.type" class="form-control">
                <option
                  v-for="type in types"
                  :key="type.title"
                  :value="type.title"
                  >{{ type.title }}</option
                >
              </select>
            </div>

            <div class="col-6">
              <label for="">Status</label>
              <base-radio v-model="form.status" inline name="1"
                >Ativo</base-radio
              >
              <base-radio v-model="form.status" inline name="0"
                >Inativo</base-radio
              >
            </div>
          </div>

          <!-- botoes -->
          <div class="col-12">
            <base-button
              class="mt-3"
              @click.prevent.stop="goBack()"
              type="danger"
              >Cancelar</base-button
            >
            <base-button class="mt-3" native-type="submit" type="success"
              >Salvar</base-button
            >
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import { BaseRadio } from '@/components/index'

import Vue from 'vue'

import swal from 'sweetalert2'

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

export default {
  data: () => ({
    form: {
      status: 1
    },
    types: []
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.form = (await this.$http.get(`categories/${id}`)).data
      }
    },
    async submit () {
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`categories/${id}`, this.form))
              .status
          }
          if (!id) { status = (await this.$http.post('categories', this.form)).status }
        } catch (e) {
          status = e.response.status
        }

        if (status === 200) {
          swal({
            title: 'Salvo!',
            text: `"${this.form.title}" salvo com sucesso.`,
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          this.goBack()
        } else {
          swal({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Ok',
            showConfirmButton: true
          })
        }
      } else {
        swal({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          type: 'error',
          confirmButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Ok',
          showConfirmButton: true
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    validate () {
      const form = this.form
      if (!form.title || !form.title.trim()) return false
      if (!form.type) return false
      return true
    },
    setTypes () {
      const types = [
        {
          title: 'Eventos'
        },
        {
          title: 'Notícias'
        },
        {
          title: 'Produtos'
        }
      ]

      this.types = types
    }
  },
  mounted () {
    this.getData()
    this.setTypes()
  },
  components: {
    BaseRadio
  }
}
</script>
<style></style>
